import { createTheme } from "@mui/material";
import { grey, purple } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    extra: {
      border: string;
    };
  }
  interface ThemeOptions {
    extra?: {
      border?: string;
    };
  }
  interface TypographyVariants {
    prices: React.CSSProperties;
    posterTitle: React.CSSProperties;
    posterSubtitle: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    prices?: React.CSSProperties;
    posterTitle?: React.CSSProperties;
    posterSubtitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    prices: true;
    posterTitle: true;
    posterSubtitle: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    break: Palette["primary"];
  }
  interface PaletteOptions {
    break: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: purple[300],
    },
    break: {
      main: grey[50],
    },
  },
  typography: {
    fontSize: 18,
    fontFamily: "Circular",
    h1: {
      fontSize: "2.5em"
    },    
    h2: {
      fontSize: "2.5em"
    },
    h3: {
      fontSize: "2em",
    },
    h4: {
      fontSize: "2em",
    },
    h5: {
      fontSize: "1em",
    },
    subtitle1: {
      fontWeight: 400,
      opacity: 0.6,
      fontSize: "1.3em",
    },
    subtitle2: {
      fontWeight: 400,
      opacity: 0.6,
      fontSize: "1em",
    },
    body1: {
      fontWeight: 400,
      opacity: 1,
      fontSize: "1em",
    },    
    prices: {
      color: grey[900],
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },
  extra: {
    border: grey[600],
  },
});

export default theme;
