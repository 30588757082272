import React from "react";
import { AppProps } from "next/app";
import Script from "next/script";
import Footer from "../components/Footer/Footer";
import { ThemeProvider } from "@mui/material/styles";
import { Toolbar } from "@mui/material";
import { MainAppBar } from "../components/MainAppBar";
import { CartProvider } from "react-use-cart";
import "./style.css";
import theme from "../utils/theme";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <CartProvider>
        <Script
          id="ZwSg9rf6GA"
          data-host="https://microanalytics.io"
          src="https://microanalytics.io/js/script.js"
          strategy="beforeInteractive"
        />
        <MainAppBar />
        {/* // Second Toolbar rendered 
        to prevent content from being hidden behind the app bar */}
        <Toolbar />
        <Component {...pageProps} />
        <Footer />
      </CartProvider>
    </ThemeProvider>
  );
}

export default MyApp;
