import { IconButton, AppBar, Toolbar, Box, Typography } from "@mui/material";
import { useCart } from "react-use-cart";

import Logo from "./Logo";

import Link from "next/link";

import ShoppingBasketIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";

const MainAppBar = () => {
  const { totalItems } = useCart();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Link href="/">
          <Box sx={{ cursor: "pointer" }}>
            <Logo inverted />
          </Box>
        </Link>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link href="/inspo" >
            <Typography marginRight="1rem" sx={{ cursor: "pointer" }}>Inspiration</Typography>
          </Link>
          <Link href="/cart">
            <IconButton>
              <Badge badgeContent={totalItems} color="secondary">
                <ShoppingBasketIcon sx={{ color: "white" }} />
              </Badge>
            </IconButton>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { MainAppBar };
