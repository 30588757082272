import { Grid, Box, Typography, Stack } from "@mui/material";
import Link from "next/link";
import { useEffect } from "react";
import Logo from "../Logo";
import Amex from "./icons/Amex";
import Klarna from "./icons/Klarna";
import MasterCard from "./icons/MasterCard";
import Visa from "./icons/Visa";

const Footer = () => {
  return (
    <Box minHeight="15vh" bgcolor="primary.main" color="white" padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Box marginBottom={1}>
            <Typography variant="h6">Allmänt</Typography>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <Link href="/aboutUs">
              <Typography>Om oss</Typography>
            </Link>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <Link href="/faq">
              <Typography>Vanliga frågor</Typography>
            </Link>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <Link href="/contact">
              <Typography>Kontakta oss</Typography>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box marginBottom={1}>
            <Typography variant="h6">Mer info</Typography>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <Link href="/legal/privacy-policy">
              <Typography>Sekretesspolicy</Typography>
            </Link>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <Link href="/legal/terms-and-conditions">
              <Typography>Köpvillkor</Typography>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display={"flex"} justifyContent={"end"}>
            <Logo inverted />
          </Box>
          <Stack
            direction={"row"}
            spacing={1}
            padding={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <MasterCard />
            <Visa />
            <Amex />
            <Klarna />
          </Stack>
            <Box textAlign="right">
              <Typography variant="subtitle1">
                Stockiful &copy; {new Date().getFullYear()}
              </Typography>
            </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
