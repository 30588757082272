const Logo = ({ inverted }) => (
  <svg
    width="200"
    height="46"
    viewBox="0 0 458 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <rect width="458" height="91" fill="white" fillOpacity="0.0" />
      <path
        d="M0 72.468C0.732 79.3 6.954 91.012 25.01 91.012C40.748 91.012 48.312 81.008 48.312 71.248C48.312 62.464 42.334 55.266 30.5 52.826L21.96 50.996C18.666 50.386 16.47 48.556 16.47 45.628C16.47 42.212 19.886 39.65 24.156 39.65C30.988 39.65 33.55 44.164 34.038 47.702L47.58 44.652C46.848 38.186 41.114 27.328 24.034 27.328C11.102 27.328 1.586 36.234 1.586 46.97C1.586 55.388 6.832 62.342 18.422 64.904L26.352 66.734C30.988 67.71 32.818 69.906 32.818 72.59C32.818 75.762 30.256 78.568 24.888 78.568C17.812 78.568 14.274 74.176 13.908 69.418L0 72.468Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M78.9264 11.224H64.2864V19.642C64.2864 25.01 61.3584 29.158 55.0144 29.158H51.9644V43.554H62.8224V71.492C62.8224 83.082 70.1424 90.036 81.8544 90.036C86.6124 90.036 89.5404 89.182 91.0044 88.572V75.152C90.1504 75.396 87.9544 75.64 86.0024 75.64C81.3664 75.64 78.9264 73.932 78.9264 68.686V43.554H91.0044V29.158H78.9264V11.224Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M128.714 76.25C120.784 76.25 113.464 70.394 113.464 59.17C113.464 47.824 120.784 42.09 128.714 42.09C136.644 42.09 143.964 47.824 143.964 59.17C143.964 70.516 136.644 76.25 128.714 76.25ZM128.714 27.328C110.78 27.328 97.2378 40.626 97.2378 59.17C97.2378 77.592 110.78 91.012 128.714 91.012C146.648 91.012 160.19 77.592 160.19 59.17C160.19 40.626 146.648 27.328 128.714 27.328Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M197.52 42.334C205.572 42.334 209.354 47.458 210.818 52.46L225.336 47.58C222.774 37.454 213.502 27.328 197.154 27.328C179.708 27.328 166.044 40.626 166.044 59.17C166.044 77.592 179.952 91.012 197.642 91.012C213.624 91.012 223.018 80.764 225.702 70.76L211.428 66.002C210.086 70.638 205.938 76.128 197.642 76.128C189.346 76.128 182.27 70.028 182.27 59.17C182.27 48.312 189.224 42.334 197.52 42.334Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M293.388 29.158H272.16L251.42 51.484V0.853998H235.194V89.182H251.42V73.322L258.13 66.246L273.99 89.182H293.876L269.354 54.412L293.388 29.158Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M317.129 89.182V29.158H300.902V89.182H317.129Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M359.876 14.274C362.56 14.274 364.146 14.64 365 14.884V1.22C363.658 0.61 360.364 0 356.948 0C343.65 0 335.11 8.784 335.11 22.326V29.158H325.106V43.066H335.11V89.182H351.458V43.066H365V29.158H351.458V22.57C351.458 15.86 356.216 14.274 359.876 14.274Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M412.152 89.182H427.646C427.402 87.108 427.036 82.96 427.036 78.324V29.158H410.81V64.05C410.81 71.004 406.662 75.884 399.464 75.884C391.9 75.884 388.484 70.516 388.484 63.806V29.158H372.258V67.1C372.258 80.154 380.554 90.768 395.072 90.768C401.416 90.768 408.37 88.328 411.664 82.716C411.664 85.156 411.908 87.962 412.152 89.182Z"
        fill={inverted ? "white" : "black"}
      />
      <path
        d="M458.139 89.182V0.853998H441.913V89.182H458.139Z"
        fill={inverted ? "white" : "black"}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="458" height="91" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
